@import 'Components/colors.scss';
@import 'diff-table.scss';

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system,
  BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #{map-get($colors, 'text')};
  background-color: #{map-get($colors, 'darkBackground')};
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  flex-direction: column;
  line-height: 1.4;

  #root {
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
  }

  .p-component,
  textarea {
    font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system,
    BlinkMacSystemFont, sans-serif;
    font-size: 14px;
  }

  .p-inputtext {
    font-size: 14px;
    padding: 9px;
    border: 2px solid #{map-get($colors, 'white')};
  }

  a {
    color: #{map-get($colors, 'white')};

    &:hover,
    &:active {
      color: #{map-get($colors, 'white')};
      text-decoration: underline;
    }

    &.external-link {
      span {
        /* span is text element */
        padding-right: 5px;
      }

      i,
      span {
        vertical-align: middle;
      }
    }
  }

  .p-sidebar {
    padding: 0;
  }
}

iframe {
  border: none;
}

.p-sidebar {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3 {
  font-family: 'Exo 2', sans-serif;
}

h1 {
  color: #{map-get($colors, 'text')};
  font-size: 32px;
  font-weight: 300;
  padding: 0 0 20px 0;
  margin: 0;
}

h2 {
  color: #{map-get($colors, 'white')};
  font-size: 18px;
  padding: 20px 0 5px 0;
  margin: 0;
  font-weight: bold;
}

h3 {
  color: #{map-get($colors, 'white')};
  font-size: 16px;
  padding: 18px 0 5px 0;
  margin: 0;
  font-weight: normal;
}

h3.line {
  border-bottom: 1px solid #6b6b6b;
}

h4 {
  color: #{map-get($colors, 'white')};
  font-size: 14px;
  padding: 10px 0 5px 0;
  margin: 0 0 0 0;
  font-weight: bold;
}

h4.special {
  border-bottom: 1px solid #6b6b6b;
}

h5 {
  font-size: 14px;
  margin: 0;
  padding: 10px 0 5px 0;
}

.disabled {
  &,
  h1,
  h2 {
    color: #929099;
  }
}

p {
  margin: 0 0 10px 0;
}

ul {
  padding-left: 20px;
  margin: 0;
}

li {
  padding-bottom: 8px;
}

.marginBottom {
  margin-bottom: 10px !important;
}

@media screen and (min-width: 640px) {
  .p-sm-auto {
    width: auto;
  }
  .sm-hide {
    display: none !important;
  }
}

@media screen and (max-width: 639px) {
  .sm-show {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .p-md-auto {
    width: auto;
  }
  .md-hide {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .md-show {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .lg-auto {
    width: auto;
  }
  .lg-hide {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .lg-show {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .xl-hide {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .xl-show {
    display: none;
  }
}

//Prime overrides
.p-dialog {
  min-width: 640px;
  max-height: 90%;
  overflow-y: hidden;
  max-width: 90%; // IE

  .p-dialog-titlebar {
    display: flex;
    background-color: #{map-get($colors, 'darkBackground')};
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .p-dialog-title {
      font-family: 'Exo 2', sans-serif;
      font-size: 20px;
      flex: 1 0 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
    }
  }

  .p-dialog-content {
    background-color: #{map-get($colors, 'darkBackground')};
    border: none;
    border-top: 1px solid #{map-get($colors, 'dividerGray')};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 3px;
  }

  .p-dialog-footer {
    background-color: #{map-get($colors, 'darkBackground')};
    border: none;
    border-top: 1px solid #{map-get($colors, 'dividerGray')};
  }

  .pi-times:before {
    font-size: 28px;
  }
}

body .p-dialog-enter-done,
body .p-dialog-enter-active {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

body .p-growl .p-growl-item-container {
  border-radius: 4px;

  .p-growl-title {
    padding: 0 18px 0 0;
  }

  &.p-growl-message-error {
    .p-growl-image {
      display: none;
    }

    .p-growl-item .p-growl-message {
      margin: 0;
    }
  }

  &.p-growl-message-success {
    .p-growl-image {
      left: 15px;
      top: 5px;
    }
  }
}

body .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #{map-get($colors, 'primary')};
}

body .p-dropdown-panel {
  background-color: #{map-get($colors, 'white')};
  border-radius: 4px;
  border: none;
  overflow: hidden;

  &.p-input-overlay-visible {
    min-width: 150px !important;
  }

  .p-dropdown-items {
    min-width: 150px !important;

    .p-dropdown-item {
      background-color: #{map-get($colors, 'white')};
      color: #{map-get($colors, 'invert')};

      &:not(.p-highlight):not(.p-disabled):hover {
        background-color: #{map-get($colors, 'primaryFaded')};
        color: #{map-get($colors, 'white')};
      }

      &.p-highlight {
        background-color: #{map-get($colors, 'primary')};
        color: #{map-get($colors, 'white')};
      }
    }
  }

  .p-dropdown-items-wrapper {
    overflow-x: hidden;
  }
}

body .p-multiselect-panel {
  background-color: #{map-get($colors, 'white')};

  .p-multiselect-items {
    .p-multiselect-item {
      color: #{map-get($colors, 'invert')};

      &:not(.p-highlight):not(.p-disabled):hover {
        background-color: #{map-get($colors, 'primary')};
        color: #{map-get($colors, 'defaultInteractText')};
      }

      &.p-highlight {
        color: #{map-get($colors, 'defaultInteractText')};
      }
    }
  }
}

body .p-datepicker .p-monthpicker {
  background-color: white;

  .p-monthpicker-month {
    padding: 0.3em 0.5em;
    color: #{map-get($colors, 'defaultInteractText')};

    &:not(.p-highlight):hover {
      border-radius: 15px;
      color: #{map-get($colors, 'white')};
    }

    &.p-highlight {
      border-radius: 15px;
      background-color: #{map-get($colors, 'primary')};
    }
  }
}

body .p-datepicker {
  background-color: white;
  border-radius: 15px;
  color: #{map-get($colors, 'defaultInteractText')};

  .p-datepicker-header {
    background-color: white;

    .p-datepicker-title {
      color: #{map-get($colors, 'defaultInteractText')};
    }
  }

  &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background-color: #{map-get($colors, 'defaultInteract')};
  }

  table td > span {
    color: #{map-get($colors, 'defaultInteractText')};

    &.p-highlight {
      background-color: #{map-get($colors, 'primary')};
    }
  }
}

body .p-datepicker table td.p-datepicker-today > span {
  background-color: #{map-get($colors, 'accentFaded')};
}

.flextable table {
  table-layout: auto !important;
}

.flextable th,
.flextable td,
.flextable thead th,
.flextable tbody td,
.flextable tfoot td,
.flextable tfoot th {
  width: auto;
}

.flex-auto-width .p-component {
  align-self: start;
}

@media screen and (max-width: 640px) {
  body .p-dialog {
    min-width: 100%;
    max-width: 100%;
  }

  h1 {
    font-size: 28px;
  }
}

@media screen and (min-width: 641px) and (max-width: 992px) {
  .button-text-lg-show.p-button {
    padding: 2px 7px;

    .p-button-icon-left {
      position: initial;
      margin-top: 0;
    }

    .p-button-text {
      display: none;
    }
  }
}

@media screen and (max-width: 660px) {
  .button-text-md-hide {
    padding: 2px 7px;

    .p-button-icon-left {
      position: initial;
      margin-top: 0;
    }

    .p-button-text {
      display: none;
    }
  }
}

.flex-auto-width .p-component {
  align-self: start;
}

.fade-enter,
.fade-appear {
  opacity: 0 !important;
  transform: scale(0.8);
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1 !important;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}

.fade-leave {
  opacity: 1;
  transform: scale(1);
}

.fade-leave-active {
  opacity: 0 !important;
  transform: scale(0.8);
  transition: opacity 200ms, transform 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.expand-enter {
  max-height: 200px !important;
  transition: max-height 1s;
}

.expand-enter-active,
.expand-enter-done {
  max-height: 50px !important;
  transition: max-height 1s;
}

.expand-exit {
  max-height: 50px !important;
  transition: max-height 1s;
}

.expand-exit-active,
.expand-exit-done {
  max-height: 200px !important;
  transition: max-height 1s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// rc-tooltip styling
body .rc-tooltip-arrow {
  border-bottom-color: #{map-get($colors, 'darkPanel')};
}

body .rc-tooltip-inner {
  background-color: #{map-get($colors, 'darkPanel')};
  font-size: 16px;
}

body .p-slider {
  .p-slider-range {
    background-color: #{map-get($colors, 'primary')};
  }

  .p-slider-handle {
    border: 2px solid #{map-get($colors, 'primary')};
  }

  &:not(.p-disabled) .p-slider-handle:hover {
    background-color: #{map-get($colors, 'primary')};
    border: 2px solid #{map-get($colors, 'primary')};
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .fix-ie-table-width {
    &,
    > .p-datatable,
    > .p-datatable-wrapper {
      display: table !important;
    }
  }
}

.p-growl-details {
  white-space: pre-line;
}

.clickable {
  cursor: pointer;
}

.info-product-icon .pi.pi-trash,
.info-product-icon .pi.pi-lock,
.info-product-icon .pi.pi-unlock,
.unlock-loading-spinner {
  font-size: 22px !important;
}

.info-product-icon .pi.pi-lock {
  transition: 0.3s color;
  color: #999 !important;
}

.info-product-icon .pi.pi-unlock, .unlock-loading-spinner {
  color: #{map-get($colors, 'primaryFaded')} !important;

  &:hover {
    color: #{map-get($colors, 'primary')} !important;
  }
}

.p-datatable-row-locked {
  color: #999 !important;
}

.padded-fields div:not(:last-child) {
  padding-bottom: 10px;
}

.padded-fields h3 {
  margin-bottom: 10px;
}

.flex {
  display: flex;
}
.truncate {
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-button-text-icon-left .p-button-text {
  padding-right: 0.4em !important;
}
